import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { graphql, Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';

import lodash from 'lodash';
import { IHSRItem } from '../../../modules/common/model/graphql-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterBar } from '../../../modules/cs/common/components/filter-bar/filter-bar';
import { Col, Row } from 'react-bootstrap';
import { HSRItem } from '../../../modules/hsr/common/components/hsr-item';
import { HSRItemArray } from '../../../modules/hsr/model/hsr-item-spec';

interface IHSRCharacterNodes {
  nodes: IHSRItem[];
}

interface IHSRCharacterEntry {
  allCharacters: IHSRCharacterNodes;
}

interface IProps {
  data: IHSRCharacterEntry;
}

const HSRDBItemsPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const FILTER_STORAGE_KEY = 'hsr-items-filter';
  const OPTS_STORAGE_KEY = 'hsr-items-opts';

  const isBrowser = typeof window !== 'undefined';
  let currentOpts = isBrowser
    ? JSON.parse(window.sessionStorage.getItem(OPTS_STORAGE_KEY))
    : {};
  if (!currentOpts) {
    currentOpts = {};
  }

  const [activeFilters, setActiveFilters] = useState(
    isBrowser
      ? JSON.parse(window.sessionStorage.getItem(FILTER_STORAGE_KEY))
      : {} || {}
  );
  const [sortOption, setSortOption] = useState(currentOpts.sort || null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search items...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '2★', value: '2' },
          { label: '3★', value: '3' },
          { label: '4★', value: '4' },
          { label: '5★', value: '5' }
        ]
      },
      { key: 'speciality', type: 'dropdown', values: HSRItemArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: isBrowser
      ? JSON.parse(window.sessionStorage.getItem(FILTER_STORAGE_KEY)) || {}
      : {}
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'itemId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.speciality && activeFilters.speciality !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.type &&
            emp.type.length > 0 &&
            emp.type.indexOf(activeFilters.speciality) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    if (isBrowser) {
      window.sessionStorage.setItem(
        FILTER_STORAGE_KEY,
        JSON.stringify(activeFilters || {})
      );
      const newOpts = {
        sort: sortOption
      };
      window.sessionStorage.setItem(
        OPTS_STORAGE_KEY,
        JSON.stringify(newOpts || {})
      );
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption, isBrowser]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke'}
      game="hsr"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Items</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_itemslist.png"
            alt="Items"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Items</h1>
          <h2>List of available items in Honkai: Star Rail.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Items"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} item
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar hsr with-color">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <Row xs={1} xxl={2} className="relic-set-container">
        {visibleCharacters.map((emp, index) => {
          return (
            <Col key={index}>
              <HSRItem slug={emp.slug} mode="card" />
            </Col>
          );
        })}
      </Row>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../../images/starrail/no_results.png"
            alt="No results"
          />
          <p>No item found. Try changing your filter.</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default HSRDBItemsPage;

export const Head: React.FC = () => (
  <Seo
    title="Items | Honkai: Star Rail | Prydwen Institute"
    description="List of available items in Honkai: Star Rail."
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulHsrItem(sort: { fields: name, order: ASC }) {
      nodes {
        ...HsrItemFieldsFragment
      }
    }
  }
`;
