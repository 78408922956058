import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IHSRItem } from '../../../common/model/graphql-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

import './hsr-item-popover.scss';

interface IProps {
  item: IHSRItem;
}

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

export const HSRItemPopover: React.FC<IProps> = ({ item }) => {
  const image = item.image
    ? getImage(item.image.localFile.childImageSharp)
    : null;

  return (
    <div className="hsr-item-popover">
      <div className={`top rarity-${item.rarity}`}>
        {image && (
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${item.name}`}
          />
        )}
        <h5>{item.name}</h5>
      </div>
      <div className="bottom">
        {item.details && <>{renderRichText(item.details, options)}</>}
        <h5>Source</h5>
        {item.source ? (
          <>{renderRichText(item.source, options)}</>
        ) : (
          <p>Source missing.</p>
        )}
      </div>
    </div>
  );
};
