import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IHSRItem } from '../../../common/model/graphql-types';

import './hsr-item.scss';
import { CustomPopover } from '../../../cs/common/components/custom-popover';
import { HSRItemPopover } from './hsr-item-popover';

interface IProps {
  item: IHSRItem;
  variant?: string;
  enablePopover?: boolean;
}

export const HSRItemInline: React.FC<IProps> = ({
  item,
  variant,
  enablePopover
}) => {
  const image = item.image
    ? getImage(item.image.localFile.childImageSharp)
    : null;
  return (
    <CustomPopover
      enabled={enablePopover}
      customClass="hsr-item-popover"
      popover={<HSRItemPopover item={item} />}
    >
      <div className={`hsr-item-inline ${variant ? variant : ''}`}>
        <div>
          <div className={`hsr-image rarity-${item.rarity}`}>
            {image && (
              <GatsbyImage
                className="disable-transition"
                image={image}
                alt={`${item.name}`}
              />
            )}
          </div>
          <div className="hsr-name">
            <span>{item.name}</span>
          </div>
        </div>
      </div>
    </CustomPopover>
  );
};
