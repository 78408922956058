import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IHSRItem } from '../../../common/model/graphql-types';
import { HSRItemCard } from './hsr-item-card';
import { HSRItemInline } from './hsr-item-inline';

interface IProps {
  id?: string;
  slug: string;
  enablePopover?: boolean;
  showLabel?: boolean;
  mode: string;
  itemId?: string;
  variant?: string;
}

export const HSRItem: React.FC<IProps> = ({
  id,
  itemId,
  mode,
  slug,
  variant,
  enablePopover
}) => {
  const allHSRItem = useStaticQuery(graphql`
    query {
      allContentfulHsrItem {
        nodes {
          ...HsrItemFieldsFragment
        }
      }
    }
  `);
  if ((!id && !slug && !itemId) || !mode) {
    return <div>Unknown &nbsp;</div>;
  }

  const item = allHSRItem.allContentfulHsrItem.nodes.find((emp: IHSRItem) =>
    id ? emp.id === id : itemId ? emp.itemId === itemId : emp.slug === slug
  );

  if (!item) {
    return <div>Unknown </div>;
  }

  return (
    <>
      {mode === 'card' && <HSRItemCard item={item} />}
      {mode === 'inline' && (
        <HSRItemInline
          item={item}
          variant={variant}
          enablePopover={enablePopover}
        />
      )}
    </>
  );
};
