export const HSRItemArray = [
  { label: 'Any', value: 'all', description: '' },
  {
    label: 'Character Ascension Material',
    value: 'Character Ascension Material'
  },
  {
    label: 'Character EXP Material',
    value: 'Character EXP Material'
  },
  {
    label: 'Consumable',
    value: 'Consumable'
  },
  {
    label: 'Currency',
    value: 'Currency'
  },
  {
    label: 'Currency (World)',
    value: 'World Currency'
  },
  {
    label: 'Light Cone Ascension Material',
    value: 'Light Cone Ascension Material'
  },
  {
    label: 'Light Cone EXP Material',
    value: 'Light Cone EXP Material'
  },
  {
    label: 'Relic EXP Material',
    value: 'Relic EXP Material'
  },
  {
    label: 'Trace Material',
    value: 'Trace Material'
  },
  {
    label: 'Warp Item',
    value: 'Warp Item'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];
