import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { IHSRItem } from '../../../common/model/graphql-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

import './hsr-item.scss';
import { Accordion } from 'react-bootstrap';

interface IProps {
  item: IHSRItem;
}

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

export const HSRItemCard: React.FC<IProps> = ({ item }) => {
  const image = item.image
    ? getImage(item.image.localFile.childImageSharp)
    : null;
  return (
    <>
      <div className="hsr-item">
        <div className="hsr-set-header">
          <div className={`hsr-set-image rarity-${item.rarity}`}>
            {image && (
              <GatsbyImage
                className="disable-transition"
                image={image}
                alt={`${item.name}`}
              />
            )}
          </div>
          <div className="hsr-set-name">
            <h4>{item.name}</h4>
            <div className="cone-pills">
              {item.type ? (
                item.type.sort().map((type, idx) => {
                  return (
                    <span key={idx} className="skill-type pill">
                      {type === 'Character Ascension Material' &&
                        'Character Ascen. Material'}
                      {type === 'Light Cone Ascension Material' &&
                        'Light Cone Ascen. Material'}
                      {type != 'Light Cone Ascension Material' &&
                        type != 'Character Ascension Material' && <>{type}</>}
                    </span>
                  );
                })
              ) : (
                <span className="no-spec">-</span>
              )}
            </div>
          </div>
        </div>
        <div className="hsr-set-content">
          <div className={`hsr-set-description`}>
            {item.details && <>{renderRichText(item.details, options)}</>}
          </div>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Source</Accordion.Header>
              <Accordion.Body>
                {item.source ? (
                  <>{renderRichText(item.source, options)}</>
                ) : (
                  <p>Source missing.</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};
